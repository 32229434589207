import React from 'react'
import $ from 'jquery';



const Modal =(props) => {

    let target = `#${props.modalid}`

    let okButton =  <button onClick={props.okButtonClick} data-bs-dismiss="modal" className=" btn btn-primary">{props.okButtonTitle}</button>
    if (props.okButton !== undefined) okButton = props.okButton

    let closeButton = <button  className="btn btn-secondary" data-bs-dismiss="modal">{props.closeButtonTitle}</button>

    if (props.messagebox===true){
        okButton = null;
        closeButton = <button  className="btn btn-secondary" data-bs-dismiss="modal">OK</button>
    }

    if (props.showBottomButtons===false){
        okButton = null
       closeButton = null
    }

    let modalClassname= "modal-dialog"
    if (props.modalLarge)modalClassname= "modal-dialog modal-lg"
    if (props.modalExtraLarge)modalClassname= "modal-dialog modal-xl"
    if (props.modalSmall) modalClassname= "modal-dialog modal-sm"


    return (
        <div>
            <button id={props.buttonid} style={{width:'0px', height:'0px', display:'none'}}   type="button" data-bs-toggle="modal" data-bs-target={target}/>
            <div className="modal fade" id={props.modalid} tabIndex="-1" aria-labelledby="exampleModalLabel" >
                <div className={modalClassname}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">{props.title}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {props.body}  
                        </div>
                        <div className="modal-footer">                        
                            {okButton}
                            {closeButton}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

            
    )
    
  
}



export default Modal;