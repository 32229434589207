
import parse from 'html-react-parser';


export function stringToHML(string){


        let value = string
  
        if (value===null || value==="" || value===undefined) return <div></div>
        
        return parse(value)

}

export function unixTimeToLocalString(unixtime){
      return  new Date(unixtime * 1000).toLocaleString()
}

export function dateFromTimestamp(timestamp){

     

    //    const myDate = new Date(timestamp * 1000); // convert timestamp to milliseconds and construct Date object
        
    
      //  return myDate.toLocaleString();

      var date = new Date(timestamp*1000); // Thu Apr 09 2020 14:28:32 GMT+0100 (British Summer Time)
        let year = date.getFullYear(); // 2020
        let month = date.getMonth() + 1; // 4 (note zero index: Jan = 0, Dec = 11)
        if (month < 10) month = "0" + month;
        let day = date.getDate(); // 9
        if (day < 10) day = "0" + day;
        return day + "." + month + "." + year

}


export function getGUID(){
        var nav = window.navigator;
        var screen = window.screen;
        var guid = nav.mimeTypes.length;
        guid += nav.userAgent.replace(/\D+/g, '');
        guid += nav.plugins.length;
        guid += screen.height || '';
        guid += screen.width || '';
        guid += screen.pixelDepth || '';
    
        return guid;
}



export function getRandomString(){
        var chars = "ABCDEFGHIJKLMNPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
        var string_length = 8;
        var randomstring = '';
        var charCount = 0;
        var numCount = 0;

        for (var i=0; i<string_length; i++) {
            // If random bit is 0, there are less than 3 digits already saved, and there are not already 5 characters saved, generate a numeric value. 
            if((Math.floor(Math.random() * 2) === 0) && (numCount < 3 || charCount >= 5)) {
            var rnum = Math.floor(Math.random() * 10);
            randomstring += rnum;
            numCount += 1;
        } else {
            // If any of the above criteria fail, go ahead and generate an alpha character from the chars string
            rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
            charCount += 1;
            }
        }

        return(randomstring);
}


export function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }


Number.prototype.format = function(n, x, s, c) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
            num = this.toFixed(Math.max(0, ~~n));
    
        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    };

export function formatEuro(value){

  return Number(value).format(2, 3, '.', ',');
       
}


export function helpTextToHtml(value){
      //  return parse(value)
}


   
export function convertDate(value)  {   

        if (value === null) return null
        
        let options = {  
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',        
                };
        return new Date(value).toLocaleString("de-DE",options)             
               
}

export function formatDate_(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
      }


export function convertDateYearShort(value)  {   

        if (value === null) return null
        
        let options = {  
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',        
                };
        return new Date(value).toLocaleString("de-DE",options)             
               
}




export function convertDateTime(value)  {   

        if (value===null) return null
        
        let options = {  
                year: 'numeric',
                month: '2-digit',
                day: '2-digit', 
                hour:'2-digit',
                minute:'2-digit'       
                };
        return new Date(value).toLocaleString("de-DE",options).replace(",", "")             
               
}

export function convertDateShort(value)  {   
        if (value === null) return null
        
        let options = {  
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',        
                };
        return new Date(value).toLocaleString("de-DE",options)   
}


export function convertDateWithoutYear(value)  {   
        if (value === null) return null
        
        let options = {  
                month: '2-digit',
                day: '2-digit',        
                };
        return new Date(value).toLocaleString("de-DE",options)   
}

export function chartTicks(istSekundarstufeII){
        let ticks = [0.8, 1, 2, 3, 4, 5, 6]      
        if (istSekundarstufeII){
                ticks=[16,15,14,13,12,11,10,9,8,7,6,5,4,3,2,1,0]
        }
        return ticks
}


export function convertTime(datum,zeit)  {   
      
   
        let options = {  
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',  
                };

        const date=new Date(datum).toLocaleString("de-DE",options)            
        
        return  `${date} ${zeit.substr(0,5)} Uhr`    ;               
}

export function getFloatInFormat(value) {       

        return value
      
}

export function getSekundarStufeIINoteinInFormat(value) {
       
       
                return value.toString().replace(".", ",").substr(0,4)
    
       
}

export function getNoteInFormat(value) {
       
        if (value>0){
                return value.toString().replace(".", ",").substr(0,4)
        }
       // 

        return ''
}


export function getGeldFormat(value) {
       
        if (value>0){
                return value.toFixed(2)
        }
       // 

        return ''
}

export  function sort_by (field, reverse, primer){

        var key = primer ? 
            function(x) {return primer(x[field])} : 
            function(x) {return x[field]};
     
        reverse = !reverse ? 1 : -1;
     
        return function (a, b) {
            return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
          } 
}

export function randomComponentKey(){
       
        return '_' + Math.random().toString(36).substr(2, 9);
        
}

export function timeConverter(timestamp){
        var dt = new Date(timestamp*1000);
        var hr = dt.getHours();
        var m = "0" + dt.getMinutes();
        var s = "0" + dt.getSeconds();
        return hr + ':' + m.substr(-2) + ':' + s.substr(-2);  
}

export function clearArray(value){

      
        let string = value
       
        string = value
        string = string.replace('{', '')
        string = string.replace('}', '')
        string = string.replace(/"/g, '')
        string = string.replace(/,/g, ', ')               
       



        return string
}

export function clearErledigtArray(value){

      //{"2020-04-29 12:50:45.89579","2020-04-29 12:50:45.91139"}
        let string = value       
       
        string = string.replace('{', '')
        string = string.replace('}', '')    
        string = string.replace(/"/g, '')  
       

        return string
}


export function clearEmailArray(value){

      
        let string = value       
        string = string.replace('{', '')
        string = string.replace(';}', '')    
        string = string.replace('}', '')     
        string = string.replace(/;,/g, ', ')  
        string = string.replace(/;/g, ', ')          
       
//{anne.dapper@alpenblick.sh;hp.abt@alpenblick.sh;,andreas.brugger@alpenblick.sh;}

        return string
}




export function  shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }


