import React from 'react'
import {convertDate, stringToHML, convertDateTime} from './generalfunctions.jsx'

const Schueler = (props) => {

    

    function lektionRow(lektion){
        let lektion_thema = null
        if (lektion.thema !=="") lektion_thema = lektion.thema
        let lektion_thema2 = null
        if (lektion.thema2 !=="") lektion_thema2 = lektion.thema2
        let lektion_thema3 = null
        if (lektion.thema3 !=="") lektion_thema3 = lektion.thema3

        let lektion_videourl = null
        let lektion_videourl2 = null
        let lektion_videourl3 = null
        let videosrc
        if (lektion.videourl !== "") {
            videosrc = `https://www.youtube.com/watch?v=${lektion.videourl}`
            lektion_videourl=<a href={videosrc} target="_blank"><img src="/assets/images/movie24.png" height={36} border={0} /></a>
        }
        if (lektion.videourl2 !== "") {
            videosrc = `https://www.youtube.com/watch?v=${lektion.videourl2}`
            lektion_videourl2=<a href={videosrc} target="_blank"><img src="/assets/images/movie24.png" height={36} border={0} /></a>
        }
        if (lektion.videourl3 !== "") {
            videosrc = `https://www.youtube.com/watch?v=${lektion.videourl3}`
            lektion_videourl3=<a href={videosrc} target="_blank"><img src="/assets/images/movie24.png" height={36} border={0} /></a>
        }
        
    return(
        <tr key={lektion.id}>
            <td>{convertDateTime(lektion.unterrichtszeit)}</td>
            <td><b>{lektion.thema}</b><br/>{lektion_videourl}</td>
            <td><b>{lektion_thema2}</b><br/>{lektion_videourl2}</td>
            <td><b>{lektion_thema3}</b><br/>{lektion_videourl3}</td>
            <td>{lektion.bemerkungen}</td>
        </tr>
            )
    }

   

    let buttonAbo = null
   
    if (props.activeSchuelerAbo!== null){
        buttonAbo = <button className="btn btn-primary" onClick={props.onShowActiveAbo}>{props.getString('$STR_0201')}</button>
    }

    let divAboBestaetigen = null
    if (props.angebotSchuelerAbo!==null && props.angebotSchuelerAbo.angenommen_am===null){
        divAboBestaetigen=<div style={{marginTop:'25px'}}><a className="btn btn-form btn-primary display-4" href="#" onClick={props.onShowAngebotAbo}>{props.getString('$STR_0186')}</a></div>
    }


    

    const buttonUnterrichtsanfrage=<a style={{marginBottom:'35px'}} className="btn btn-form btn-primary display-4" href="#" onClick={props.neueUnterrichtsanfrage}>{props.getString('$STR_0122')}</a>

    let abonnements = <b>{props.getString('$STR_0214')}</b>
    if (props.schueler_lektionen.length > 0){
        abonnements = <table className="table"><tbody>{props.schueler_lektionen.map(lektionRow)}</tbody></table>
    }
  
    return(
        
        <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/about7.png)'}}>
            <div className="container text-center" data-stellar-background-ratio=".5">
                <div className="row slider-wrapper">
                    <div className="col-md-12">
                        <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                            <div className="row justify-content-center pt-5"> 
                                <div className="col-3">                       
                                    <img src="/assets/images/teacher.png" />  
                                    {buttonAbo}   
                                </div>
                                <div className="col-9">  
                                    {buttonUnterrichtsanfrage}
                                    <h3 className="styled">{props.getString('$STR_0215')}</h3>  
                                    <div style={{height:'300px', overflow:'auto'}}>
                                        {abonnements}
                                        {divAboBestaetigen}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 	    
        </section>

    )


}

export default Schueler