import React from 'react'
import {convertDate, stringToHML, convertDateTime} from './generalfunctions.jsx'


const Admin=(props) =>{

    const dropDownFilter=(
       
        <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {props.schuelerFilter}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button className="dropdown-item" onClick={props.neuerSchueler}>Neuer Schüler +</button>
                <div className="dropdown-divider"></div>
                <button onClick={()=>props.setSchuelerFilter('aktiv')}  className="dropdown-item">aktiv</button>
                <button onClick={()=>props.setSchuelerFilter('angemeldet')} className="dropdown-item">angemeldet</button>
                <button onClick={()=>props.setSchuelerFilter('inaktiv')}  className="dropdown-item">inaktiv</button>
                <button onClick={()=>props.setSchuelerFilter('alle')}  className="dropdown-item">alle</button>         
            </div>
        </div>
    )

    let titleSchuelerdropDown='(nicht gewählt)';
    if (props.activeSchueler !== null){
        titleSchuelerdropDown = props.activeSchueler.name;
    }

    function schuelerItem(schueler){
        return <button key={schueler.id}  onClick={()=>props.onSchuelerClick(schueler)} className="dropdown-item" type="submit">{schueler.name} ({schueler.anzahl_abos} Abos)</button>

    }
    
    const dropDownSchueler=(
        <div>
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {titleSchuelerdropDown}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {props.filteredSchueler.map(schuelerItem)}           
                </div>
            </div>
        </div>
    )

    let nav_seite1_className = "nav-link";
    let nav_seite2_className = "nav-link";
    let nav_seite3_className = "nav-link";
    if (props.adminPage===1) nav_seite1_className = "nav-link active";
    if (props.adminPage===2) nav_seite2_className = "nav-link active";
    if (props.adminPage===3) nav_seite3_className = "nav-link active";

    let numAbos=0
    if (props.activeSchueler !==null && props.activeSchueler.unterrichtabos!==undefined) numAbos=props.activeSchueler.unterrichtabos.length

    let abos=null
    if (props.activeSchueler !==null && props.activeSchueler.id > 0){
        abos=(

            <li className="nav-item ">
                <button  onClick={()=>props.setAdminPage(2)}  className={nav_seite2_className}>Abos ({numAbos})</button>
            </li>
        )
    }

    let nav = (
        <ul className="nav nav-tabs">
            <li className="nav-item ">
                <button onClick={()=>props.setAdminPage(1)} className={nav_seite1_className}>Schülerdaten</button>
            </li>
            {abos}
        </ul>
    )

    const toolbuttons=(
        <>
            <div style={{display:'flex',width:'100%'}}> {dropDownFilter} {dropDownSchueler} </div> 
            <hr/>
        </>
    )

    function getOrt(ort){
        if (props.language==="de") return ort.ort_de
        if (props.language==="en") return ort.ort_en
        if (props.language==="es") return ort.ort_es
        if (props.language==="fr") return ort.ort_fr
        if (props.language==="it") return ort.ort_it
        if (props.language==="pt") return ort.ort_pt
        return null

    }

    function getThema(abo){
        if (props.language==="de") return abo.thema_de
        if (props.language==="en") return abo.thema_en
        if (props.language==="es") return abo.thema_es
        if (props.language==="fr") return abo.thema_fr
        if (props.language==="it") return abo.thema_it
        if (props.language==="pt") return abo.thema_pt
        return null
    }

    let aboDropdowntitle = '(Abo auswählen)';
    if (props.activeAbo!==null && props.activeAbo.id>0){
        aboDropdowntitle = `${convertDate(props.activeAbo.datum_beginn_abo)} ${getThema(props.activeAbo)}`;
    }

    function aboRow(abo){
        const entry = `${convertDate(abo.datum_beginn_abo)} ${getThema(abo)} ${abo.status}`
        return(
            <button  onClick={()=>props.onAboClick(abo)} className="dropdown-item">{entry}</button>       
        )
    }

    let abodropdownitems = null
    if (props.activeSchueler !==null && props.activeSchueler.unterrichtabos !==undefined){
        abodropdownitems = props.activeSchueler.unterrichtabos.map(aboRow)
    }

    let statustitle = 'nicht gewählt'
    if (props.activeSchueler !== null){
        statustitle = props.activeSchueler.status
    }

    let dropDownStatusValues=
        <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {statustitle}
            </button>
            <ul className="dropdown-menu">
                <li><button onClick={()=>props.changeSchuelerStatus('angemeldet')}  className="dropdown-item">angemeldet</button></li>
                <li><button onClick={()=>props.changeSchuelerStatus('aktiv')}className="dropdown-item">aktiv</button></li>
                <li><button onClick={()=>props.changeSchuelerStatus('inaktiv')}className="dropdown-item">inaktiv</button></li>            
            </ul>
        </div>



    let dropDownAbo=
        <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {aboDropdowntitle}
            </button>
            <ul className="dropdown-menu">
            <li><button onClick={props.neuesAbo} className="dropdown-item">Neues Abo +</button></li>
            <div className="dropdown-divider"></div>
                {abodropdownitems}
            </ul>
        </div>

    


    let angebot_html = null
    let kursthema = '(nicht gewählt'
    let unterrichtsort = '(nicht gewählt'
    let preis_pro_stunde = 0
    let preis_normal = 0
    let rabattprozent = 0;
    let rabatt_prozentgruppe = 20;
    let rabatt_fuer_abo = 0;
    let rabatt_fuer_gruppenunterricht = 0;
    let gesamtpreis = 0

    let form_abodaten= null
    if (props.activeAbo !== null && props.activeSchueler !== null){
        
        
        if (props.activeAbo.lektionsdauer=== 45){preis_pro_stunde = props.lektionenpreise.preis_pro_45min_erwachsene; if (props.activeAbo.altersgruppe === "kinder") preis_pro_stunde = props.lektionenpreise.preis_pro_45min_kinder  }
        if (props.activeAbo.lektionsdauer=== 60){preis_pro_stunde = props.lektionenpreise.preis_pro_60min_erwachsene; if (props.activeAbo.altersgruppe === "kinder") preis_pro_stunde = props.lektionenpreise.preis_pro_60min_kinder }
        if (props.activeAbo.lektionsdauer=== 30) {preis_pro_stunde = props.lektionenpreise.preis_pro_30min_erwachsene; if (props.activeAbo.altersgruppe === "kinder") preis_pro_stunde = props.lektionenpreise.preis_pro_30min_kinder }

        preis_normal = preis_pro_stunde * props.activeAbo.anzahl_lektionen;
        if (props.activeAbo.anzahl_lektionen >5 && props.activeAbo.anzahl_lektionen<20) {rabatt_fuer_abo = preis_normal / 100 * 10;rabattprozent=10;}
        if (props.activeAbo.anzahl_lektionen >19 ) {rabatt_fuer_abo = preis_normal / 100 * 15;rabattprozent=15;}
        rabatt_fuer_gruppenunterricht = 0;
        if (props.activeAbo.gruppenunterricht ==1)rabatt_fuer_gruppenunterricht = preis_normal / 100 * rabatt_prozentgruppe;


      
        gesamtpreis = preis_normal - rabatt_fuer_abo  - rabatt_fuer_gruppenunterricht + props.activeAbo.wegpauschale;


        
        if (props.activeAbo.kursthema_id > 0){
            props.kursthemen.forEach(
                kt=>{
                    if (kt.id===props.activeAbo.kursthema_id)kursthema= getThema(kt)
                }
            )
        }


        function kursthemaRow(thema){
            return(
                <li><button onClick={()=>props.setKursthemaid(thema.id)} className="dropdown-item">{getThema(thema)}</button></li>
            )
        }

        let dropDownKursthemen =(
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {kursthema}
                </button>
                <ul className="dropdown-menu">
                  {props.kursthemen.map(kursthemaRow)}                
                </ul>
            </div>
        )

        
        if (props.activeAbo.unterrichtsort_id > 0){
            props.unterrichtsorte.forEach(
                ort=>{
                    if (ort.id===props.activeAbo.unterrichtsort_id)unterrichtsort= getOrt(ort)
                }
            )
        }

        function unterrichtsortRow(ort){
            return(
                <li><button onClick={()=>props.setUnterrichtsort(ort.id)} className="dropdown-item">{getOrt(ort)}</button></li>
            )
        }

        let dropDownUnterrichtsorte =(
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {unterrichtsort}
                </button>
                <ul className="dropdown-menu">
                  {props.unterrichtsorte.map(unterrichtsortRow)}                
                </ul>
            </div>
        )

        let altersgruppe = 'erwachsene'
        if (props.activeAbo.altersgruppe !==""){
            altersgruppe = props.activeAbo.altersgruppe
        }        
        let dropDownAltersgruppen =(
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {altersgruppe}
                </button>
                <ul className="dropdown-menu">
                    <li><button onClick={()=>props.setAltersgruppe("erwachsene")} className="dropdown-item">erwachsene</button></li>
                    <li><button onClick={()=>props.setAltersgruppe("kinder")} className="dropdown-item">kinder</button></li>        
                </ul>
            </div>
        )



        let lektionsdauer = 30
        if (props.activeAbo.lektionsdauer > 0){
            lektionsdauer = props.activeAbo.lektionsdauer
        }        
        let dropDownLektionsdauer =(
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {lektionsdauer}
                </button>
                <ul className="dropdown-menu">
                    <li><button onClick={()=>props.setLektionsdauer(30)} className="dropdown-item">30</button></li>
                    <li><button onClick={()=>props.setLektionsdauer(45)} className="dropdown-item">45</button></li>      
                    <li><button onClick={()=>props.setLektionsdauer(60)} className="dropdown-item">60</button></li>          
                </ul>
            </div>
        )

        let aboStatus = "angebot"
        if (props.activeAbo.status !== ""){
            aboStatus = props.activeAbo.status
        }
        let dropDownAboStatus =(
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {aboStatus}
                </button>
                <ul className="dropdown-menu">
                    <li><button onClick={()=>props.setAboStatus("angebot")} className="dropdown-item">angebot</button></li>
                    <li><button onClick={()=>props.setAboStatus("aktiv")} className="dropdown-item">aktiv</button></li>      
                    <li><button onClick={()=>props.setAboStatus("inaktiv")} className="dropdown-item">inaktiv</button></li>          
                </ul>
            </div>
        )


        
        let tr_wegpauschale = null
        let tr_zahlungsbedingungen = null
        let trs_abopreis = null
        let tr_wegpauschale_input = null
        
        tr_wegpauschale=
            <tr>
                <td>Wegpauschale bei Heimunterricht</td>
                <td align="right">{props.activeAbo.wegpauschale.toFixed(2)} CHF</td>
            </tr>

            tr_wegpauschale_input=(
                <tr>
                    <td>Wegpauschale bei Heimunterricht</td><td><input type="number" onChange={props.changeAbo} name="wegpauschale" className="form-control" value={props.activeAbo.wegpauschale} /></td>                   
                </tr>
            )

        tr_zahlungsbedingungen=
        <tr>
            <td>Zahlungsbedingungen</td><td><b>{props.activeAbo.zahlungsbedingungen}</b></td>                 
        </tr>
    
        trs_abopreis=
        <>
            <br/><br/>
                <table className="table" style={{textAlign: 'left'}}>
                    <tbody>
                <tr>
                    <td>{props.activeAbo.anzahl_lektionen} Lektionen x {preis_pro_stunde.toFixed(2)} CHF</td>
                    <td align="right">{preis_normal.toFixed(2)} CHF</td>
                </tr>
                <tr>
                    <td>Rabatt für Abo (-{rabattprozent}%)</td>
                    <td align="right">- {rabatt_fuer_abo.toFixed(2)} CHF</td>
                </tr>
                <tr>
                    <td>Rabatt für Gruppenunterricht (-20%)</td>
                    <td align="right">- {rabatt_fuer_gruppenunterricht.toFixed(2)} CHF</td>
                </tr>
                    {tr_wegpauschale}
                <tr>
                    <td>Gesamtpreis</td>
                    <td align="right"><strong>{gesamtpreis.toFixed(2)} CHF</strong></td>
                </tr></tbody>
            </table> 
        </>

        let datum_angebotversendet = null
        if (props.activeAbo.angebot_versendet_am !==null){
            datum_angebotversendet=convertDate(props.activeAbo.angebot_versendet_am)
        }
            
        

        let preisberechnungen = (
            <>
            <tr>
                <td>Zahlungsbedingungen</td><td><textarea rows="3" onChange={props.changeAbo}  name="zahlungsbedingungen" className="form-control" value={props.activeAbo.zahlungsbedingungen}></textarea></td>                  
            </tr>
            <tr>
                <td>Preisberechnung (nach dem Abo-Speichern)</td>
                <td>
                    <table className="table" style={{textAlign: 'left'}}>
                        <tbody>
                        <tr>
                            <td>{props.activeAbo.anzahl_lektionen} Lektionen x {preis_pro_stunde.toFixed(2)} CHF</td>
                            <td align="right">{preis_normal.toFixed(2)} CHF</td>
                        </tr>
                        <tr>
                            <td>Rabatt für Abo (-{rabattprozent}%)</td>
                            <td align="right">- {rabatt_fuer_abo.toFixed(2)}  CHF</td>
                        </tr>
                        <tr>
                            <td>Rabatt für Gruppenunterricht (-20%)</td>
                            <td align="right">- {rabatt_fuer_gruppenunterricht.toFixed(2)}  CHF</td>
                        </tr>
                        <tr>
                            <td>Wegpauschale bei Heimunterricht</td>
                            <td align="right">+ {props.activeAbo.wegpauschale.toFixed(2)} CHF</td>
                        </tr>
                        <tr>
                            <td>Berechneter Gesamtpreis</td>
                            <td align="right">{gesamtpreis.toFixed(2)}  CHF</td>
                        </tr></tbody>
                    </table>                        
                </td>
            </tr>
            <tr>
                <td>Angebot versendet am</td><td><b>{datum_angebotversendet}</b></td>                   
            </tr>
           
            <tr>
                <td>Angebot angenommen am</td><td><b>{convertDate(props.activeAbo.angenommen_am)}</b></td>                   
            </tr>
            </>
        )

        let buttonAboLoeschen = null
        let buttonAngebotSchicken = null
        if (props.activeAbo.id > 0){
            buttonAboLoeschen=(
                <button onClick={props.aboLoeschen} className="btn btn-danger" >Abo löschen</button>
            )
            buttonAngebotSchicken=(
                <button onClick={props.aboangebotsenden} className="btn btn-secondary" >Angebot senden</button>
            )
        }


        form_abodaten=(
            <>
   
                <table className="table">
                    <tbody>
                    <tr>
                        <td width={140}>Abo - Status</td><td>{dropDownAboStatus}</td>
                    </tr>
                    <tr>
                        <td>Kurs</td><td>{dropDownKursthemen}</td>
                    </tr>
                    <tr>
                        <td>Unterrichtsort</td><td>{dropDownUnterrichtsorte}</td>
                    </tr>
                    <tr>
                        <td>Altersgruppe</td><td>{dropDownAltersgruppen}</td>
                    </tr>
                    <tr>
                        <td>Lektionsdauer</td><td>{dropDownLektionsdauer}</td>
                    </tr>
                    <tr>
                        <td colSpan="2">	                    
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="gruppenunterricht"  type="checkbox" onChange={props.changeAbo} role="switch" id="flexSwitchCheckDefault" checked={props.activeAbo.gruppenunterricht===1}/>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Gruppenunterricht zu zweit</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Anderer Schüler im Gruppenunterricht</td><td><input type="text" onChange={props.changeAbo} name="andererschueler" className="form-control" value={props.activeAbo.andererschueler} /></td>               
                    </tr>
                    <tr>
                        <td>Email des anderen Schülers</td><td><input type="text" onChange={props.changeAbo} name="andererschueler_email" className="form-control" value={props.activeAbo.andererschueler_email} /></td>               
                    </tr>
                    <tr>
                        <td>Anzahl Lektionen im Abo</td><td><input type="number" onChange={props.changeAbo}   style={{width:'100px'}} name="anzahl_lektionen" className="form-control" value={props.activeAbo.anzahl_lektionen} /></td>   
                    </tr>
                    <tr>
                        <td>Beginn des Abos</td><td><input type="date" onChange={props.changeAbo} style={{width:'200px'}} name="datum_beginn_abo" className="form-control" value={props.activeAbo.datum_beginn_abo} /></td>   
                    </tr>
                    <tr>
                        <td>Ende des Abos</td><td><input type="date" onChange={props.changeAbo} style={{width:'200px'}} name="datum_ende_abo" className="form-control" value={props.activeAbo.datum_ende_abo} /></td>   
                    </tr>
                      {tr_wegpauschale_input}
                    
                    <tr>
                        <td>Unterrichtszeit (Wochentag, Uhrzeit)</td><td><input type="text" onChange={props.changeAbo} name="unterrichtszeit" className="form-control" value={props.activeAbo.unterrichtszeit} /></td>                  
                    </tr>
                    <tr>
                        <td>Bemerkungen</td><td><textarea rows="3" name="bemerkungen" onChange={props.changeAbo} className="form-control" value={props.activeAbo.bemerkungen}></textarea></td>                  
                    </tr>
                    
                    {preisberechnungen}
                     
                </tbody>
                </table>
                <div style={{display:'flex'}}>  
                    <button onClick={()=>saveAbo()} className="btn btn-primary">Abo speichern</button>
                    {buttonAngebotSchicken}
                    {buttonAboLoeschen} 
                    
                </div>
            </>
        )

        function saveAbo(){
            let activeAbo = props.activeAbo
            activeAbo.angebot_html = zusammenfassung()
            props.saveAbo(activeAbo)
        }

        

        let angebot_titel = <>Angebot - Kostenvoranschlag</>;
        if (props.activeAbo.status=== "aktiv"){
            angebot_titel = <>Abo - <strong>aktiv</strong></>
        }
        if (props.activeAbo.status=== "inaktiv"){
            angebot_titel = <>Abo - <strong>inaktiv</strong></>
        }
        let gruppenunterricht_str = "nein"
        if (props.activeAbo.gruppenunterricht===1)gruppenunterricht_str = "ja"

        let tr_andererschueler = null
        if (props.activeAbo.andererschueler !==""){
            tr_andererschueler=(
                <tr>
                    <td>Anderer Schüler im Gruppenunterricht</td><td><b>{props.activeAbo.andererschueler}</b></td>            
                </tr>
            )
        }
        angebot_html = stringToHML(props.activeAbo.angebot_html)
    }

    function zusammenfassung (){
    
            let name = props.activeSchueler.name
            if (props.activeSchueler.name==="")name = props.activeSchueler.anmelder_name    

            let anmelder_name = props.getString('$STR_0212')
            if (props.activeSchueler.anmelder_name !==null )anmelder_name=props.activeSchueler.anmelder_name

            let andererschueler = "-"
            if (props.activeAbo.andererschueler!=="")andererschueler=props.activeAbo.andererschueler
            let andererschueler_email = "-"
            if (props.activeAbo.andererschueler_email!=="")andererschueler_email=props.activeAbo.andererschueler_email
           
            return(
              
            `<table style="textAlign: left; width:500px;" class="table">
                <tbody>
                    <tr>
                        <td width="250">${props.getString('$STR_0137')}</td>
                        <td>${anmelder_name}</td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0203')} </td>
                        <td>${name}</td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0028')}</td>
                        <td>${props.activeSchueler.email}</td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0151')}</td>
                        <td>${kursthema}</td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0111')}</td>
                        <td>${unterrichtsort}</td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0113')}</td>
                        <td>${props.activeAbo.lektionsdauer}</td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0189')}</td>
                        <td>${props.activeAbo.anzahl_lektionen}</td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0202')}</td>
                        <td>${convertDate(props.activeAbo.datum_beginn_abo)}</td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0193')}</td>
                        <td>${props.activeAbo.bemerkungen}</td>
                    </tr>
                     <tr>
                        <td>${props.getString('$STR_0153')}</td>
                        <td>${andererschueler} </td>
                    </tr>
                     <tr>
                        <td>${props.getString('$STR_0208')}</td>
                        <td>${andererschueler_email} </td>
                    </tr>

                    <tr>
                        <td>${props.getString('$STR_0195')}</td>
                        <td>${props.activeAbo.zahlungsbedingungen} </td>
                    </tr>
                    <tr>
                        <td>${props.activeAbo.anzahl_lektionen} ${props.getString('$STR_0115')} x ${preis_pro_stunde.toFixed(2)} CHF</td>
                        <td align="right">${preis_normal.toFixed(2)} CHF</td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0116')} (${rabattprozent} % ${props.getString('$STR_0117')})</td>
                        <td  align="right"><font color="#ff0000">- ${rabatt_fuer_abo.toFixed(2)} CHF</font></td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0126')} (${rabatt_prozentgruppe} % ${props.getString('$STR_0117')})</td>
                        <td align="right"><font color="#ff0000">- ${rabatt_fuer_gruppenunterricht.toFixed(2)} CHF</font></td>
                    </tr>
                    <tr>
                        <td>${props.getString('$STR_0121')}</td>
                        <td align="right">${props.activeAbo.wegpauschale.toFixed(2)} CHF</td>
                    </tr>
                    <tr>
                        <td><strong><big>${props.getString('$STR_0118')}</big></strong></td>
                        <td align="right"><strong><big>${gesamtpreis.toFixed(2)} CHF</big></strong></td>
                    </tr>
                </table>`
            )
    }

    function lektionenRow(lektion){

        if (lektion === null) return null


        let pdf = null
        if (lektion.pdf_id !== 0){
            const ref=`/media/pdf/${lektion.pdf_id}.pdf`
            pdf= <a href={ref} target="_blank"><img src="/assets/images/pdf24.png" height="24" /></a>            
        }
        let pdf2 = null
        if (lektion.pdf2_id !== 0){
            const ref=`/media/pdf/${lektion.pdf2_id}.pdf`
            pdf2= <a href={ref} target="_blank"><img src="/assets/images/pdf24.png" height="24" /></a>            
        }
        let pdf3 = null
        if (lektion.pdf3_id !== 0){
            const ref=`/media/pdf/${lektion.pdf3_id}.pdf`
            pdf3= <a href={ref} target="_blank"><img src="/assets/images/pdf24.png" height="24" /></a>            
        }
        


        let videourl = null
        if (lektion.videourl !== null && lektion.videourl !==""){
            const ref=`https://www.youtube.com/watch?v=${lektion.videourl}`
            videourl= <a href={ref} target="_blank"><img src="/assets/images/movie24.png" height="24" /></a>            
        }
        let videourl2 = null
        if (lektion.videourl2 !== null && lektion.videourl2 !==""){
            const ref=`https://www.youtube.com/watch?v=${lektion.videourl2}`
            videourl2= <a href={ref} target="_blank"><img src="/assets/images/movie24.png" height="24" /></a>            
        }
        let videourl3 = null
        if (lektion.videourl3 !== null && lektion.videourl3 !==""){
            const ref=`https://www.youtube.com/watch?v=${lektion.videourl3}`
            videourl3= <a href={ref} target="_blank"><img src="/assets/images/movie24.png" height="24" /></a>            
        }

        return(
            <tr>
                <td width="10"><big>{lektion.lfdnr}</big></td>
                <td>{convertDateTime(lektion.unterrichtszeit)}</td>
                <td>Thema 1<br/><b>{lektion.thema}</b><br/>{videourl} {pdf}<br/>{lektion.bemerkungen}</td>
                <td>Thema 2<br/><b>{lektion.thema2}</b><br/>{videourl2} {pdf2}<br/>{lektion.bemerkungen2}</td>
                <td>Thema 3<br/><b>{lektion.thema3}</b><br/>{videourl3} {pdf3}<br/>{lektion.bemerkungen3}</td>
                
                <td><a href="#" onClick={(e)=>props.editLektion(e,lektion.id,lektion.abo_id)}>Bearbeiten</a></td>
            </tr>
        )
    }

   
    

  
    let form_unterrichtdaten = (
        <>           
            <table className="table">
                <tbody>
                    {props.lektionen.map(lektionenRow)}
                </tbody>            
            </table>            
        </>
    )

    

    let form_abo = form_abodaten;
    if (props.nav_abo_modus===2) form_abo = form_unterrichtdaten;
    if (props.nav_abo_modus===3) form_abo = angebot_html;

    let nav_abo_modus_1_className="nav-link"
    let nav_abo_modus_2_className="nav-link"
    let nav_abo_modus_3_className="nav-link"
    if (props.nav_abo_modus===1)nav_abo_modus_1_className="nav-link active"
    if (props.nav_abo_modus===2)nav_abo_modus_2_className="nav-link active"
    if (props.nav_abo_modus===3)nav_abo_modus_3_className="nav-link active"

    let nav_abo = null
    if (props.activeSchueler!==null){
        nav_abo=(
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button onClick={()=>props.setNavAboModus(1)} className={nav_abo_modus_1_className}>Abo-Daten</button>
                </li>
                <li className="nav-item">
                    <button onClick={()=>props.setNavAboModus(2)} className={nav_abo_modus_2_className}>Unterricht</button>
                </li>
                <li className="nav-item">
                    <button onClick={()=>props.setNavAboModus(3)} className={nav_abo_modus_3_className}>Zusammenfassung</button>
                </li>
            </ul>
        )
    }

    let form_schuelerdaten = null

    if (props.activeSchueler !==null)

    form_schuelerdaten = (
        <>
            {props.gespeichert}
    
            <table className="table">
                <tbody>
                <tr>
                    <td width={140}>Anmeldende Person</td><td width={450}><input type="text" name="anmelder_name" value={props.activeSchueler.anmelder_name} className="form-control" onChange={props.changeSchuelerData}/></td>
                </tr>
                <tr>
                    <td>Name</td><td><input type="text" name="name" value={props.activeSchueler.name} className="form-control" onChange={props.changeSchuelerData}/></td>
                </tr>
                <tr>
                    <td>Email</td><td><input type="email" name="email" value={props.activeSchueler.email} className="form-control" onChange={props.changeSchuelerData}/></td>
                </tr>
                <tr>
                    <td>Telefon</td><td><input type="text" name="telefon" value={props.activeSchueler.telefon} className="form-control" onChange={props.changeSchuelerData}/></td>
                </tr>
                <tr>
                    <td>Strasse, Nr.</td><td><input type="text" name="strassenr" value={props.activeSchueler.strassenr} className="form-control" onChange={props.changeSchuelerData}/></td>
                </tr>
                <tr>
                    <td>Plz, Ort</td><td><input type="text" name="plzort" value={props.activeSchueler.plzort} className="form-control" onChange={props.changeSchuelerData}/></td>
                </tr>
                <tr>
                    <td>Geburtsjahr</td><td><input type="number" name="geburtsjahr" value={props.activeSchueler.geburtsjahr} className="form-control" onChange={props.changeSchuelerData}/></td>
                </tr>
                <tr>
                    <td>Bemerkungen</td><td><textarea className="form-control" name="bemerkungen" rows="3" onChange={props.changeSchuelerData} value={props.activeSchueler.bemerkungen} /></td>
                </tr>  
                <tr>
                    <td>Status</td><td align="left">{dropDownStatusValues}</td>
                </tr>    
                </tbody>     
            </table>
            <div style={{display:'flex'}}>
                <button className="btn btn-primary" onClick={props.saveSchueler}>Speichern</button>
                <button className="btn btn-danger" onClick={props.deleteSchueler}>Löschen</button>
            </div>
        </>
    )


    let content_rechts = null
    if (props.adminPage===1){
        content_rechts =form_schuelerdaten;
        dropDownAbo = null;
        if (props.activeSchueler==null ) content_rechts =null;
    }
    if (props.adminPage===2){    
        content_rechts =  <>{nav_abo} {form_abo}</>;
    if (props.activeAbo===null) content_rechts = null;   
    }
    if (props.adminPage===3){
        dropDownAbo = null;    
    }




    if (props.activeSchueler===null){
        dropDownAbo = null;
        nav = null;        
        content_rechts = null;
    }

    const contentPage=(
        <div className="row">
            <div className="col">           
                {nav}
                {dropDownAbo}
                {content_rechts}
            </div>
        </div>
    )




    return(
        <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/about5.png)'}}>    
            <div className="container" style={{marginTop:'55px'}}>
                <div className="row" style={{minHeight:'940px', backgroundColor:'white'}}>      
                    <div>{props.printButton}</div>  
                    <div className="col slider-text  slider-text-two" style={{boxShadow: '3px 2px #232124, backgroundColor:white', textAlign:'left'}} >    
                            {toolbuttons}
                            {contentPage}
                    </div>
                </div>
            </div>      
        </section>
    )

}


export default Admin