import React from 'react'
import {convertDate} from './generalfunctions.jsx'

const Unterrichtsanfrage = (props) => {

    if (props.activeAbo===null) return <></>


    function getThema(abo){
        if (props.language==="de") return abo.thema_de
        if (props.language==="en") return abo.thema_en
        if (props.language==="es") return abo.thema_es
        if (props.language==="fr") return abo.thema_fr
        if (props.language==="it") return abo.thema_it
        if (props.language==="pt") return abo.thema_pt
        return null
    }

    function kursthemaRow(thema){
        return(
            <li><button onClick={()=>props.setKursthemaid(thema.id)} className="dropdown-item">{getThema(thema)}</button></li>
        )
    }

    let kursthema = null
    
    if (props.activeAbo !== null && props.activeAbo.kursthema_id > 0){
        props.kursthemen.forEach(
            kt=>{
                if (kt.id===props.activeAbo.kursthema_id)kursthema= getThema(kt)
            }
        )
    }

    const dropdownmenu = (
       
            <div className="dropdown">
                <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {kursthema}
                </button>
                <ul className="dropdown-menu">
                    {props.kursthemen.map(kursthemaRow)}         
                </ul>
            </div>
    )

    function getOrt(ort){
        if (props.language==="de") return ort.ort_de
        if (props.language==="en") return ort.ort_en
        if (props.language==="es") return ort.ort_es
        if (props.language==="fr") return ort.ort_fr
        if (props.language==="it") return ort.ort_it
        if (props.language==="pt") return ort.ort_pt
        return null
    }

    let unterrichtsort = '(nicht gewählt'
        if (props.activeAbo.unterrichtsort_id > 0){
            props.unterrichtsorte.forEach(
                ort=>{
                    if (ort.id===props.activeAbo.unterrichtsort_id)unterrichtsort= getOrt(ort)
                }
            )
        }

    function unterrichtsortRow(ort){
        return(
            <li><button onClick={()=>props.setUnterrichtsort(ort.id)} className="dropdown-item">{getOrt(ort)}</button></li>
        )
    }

    const unterrichtsorte=(
        <div className="dropdown">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {unterrichtsort}
            </button>
            <ul className="dropdown-menu">
                {props.unterrichtsorte.map(unterrichtsortRow)}         
            </ul>
        </div>
    )

    function getAltersgruppe(altersgruppe){
        if (altersgruppe==="kinder") return props.getString('$STR_0120')
        if (altersgruppe==="erwachsene") return props.getString('$STR_0119')
        return null
    }

   
      
    let dropDownAltersgruppen =(
        <div className="dropdown">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {getAltersgruppe(props.activeAbo.altersgruppe)}
            </button>
            <ul className="dropdown-menu">
                <li><button onClick={()=>props.setAltersgruppe("erwachsene")} className="dropdown-item">{props.getString('$STR_0119')}</button></li>
                <li><button onClick={()=>props.setAltersgruppe("kinder")} className="dropdown-item">{props.getString('$STR_0120')}</button></li>        
            </ul>
        </div>
    )

    let className30 = "btn btn-outline-primary"
    let className45 = "btn btn-outline-primary"
    let className60 = "btn btn-outline-primary"
    if (props.activeAbo.lektionsdauer===30)className30 = "btn btn-outline-primary active"
    if (props.activeAbo.lektionsdauer===45)className45 = "btn btn-outline-primary active"
    if (props.activeAbo.lektionsdauer===60)className60 = "btn btn-outline-primary active"

    let preis_normal=0
    const preis_pro_30min_kinder = props.lektionenpreise.preis_pro_30min_kinder;
    const preis_pro_45min_kinder = props.lektionenpreise.preis_pro_45min_kinder;
    const preis_pro_60min_kinder = props.lektionenpreise.preis_pro_60min_kinder;
    const preis_pro_30min_erwachsene = props.lektionenpreise.preis_pro_30min_erwachsene;
    const preis_pro_45min_erwachsene = props.lektionenpreise.preis_pro_45min_erwachsene;
    const preis_pro_60min_erwachsene = props.lektionenpreise.preis_pro_60min_erwachsene;
    const preis_wegpauschale_2h = props.lektionenpreise.preis_wegpauschale_2h;
    const preis_wegpauschale_5h = props.lektionenpreise.preis_wegpauschale_5h;
    const preis_wegpauschale_10h = props.lektionenpreise.preis_wegpauschale_10h;
    const preis_wegpauschale_20h = props.lektionenpreise.preis_wegpauschale_20h;

    let preis_pro_stunde

    if (props.activeAbo.lektionsdauer=== 45){preis_pro_stunde = preis_pro_45min_erwachsene; if (props.activeAbo.altersgruppe=== "kinder")preis_pro_stunde = preis_pro_45min_kinder;  }
    if (props.activeAbo.lektionsdauer=== 60){preis_pro_stunde = preis_pro_60min_erwachsene; if (props.activeAbo.altersgruppe=== "kinder" )preis_pro_stunde = preis_pro_60min_kinder; }
    if (props.activeAbo.lektionsdauer=== 30) {preis_pro_stunde = preis_pro_30min_erwachsene; if (props.activeAbo.altersgruppe=== "kinder")preis_pro_stunde = preis_pro_30min_kinder; }

    const preis_2_stunden = 2 * preis_pro_stunde;
    const preis_5_stunden = 5 * preis_pro_stunde;
    const preis_10_stunden = 10 * preis_pro_stunde;
    const preis_20_stunden = 20 * preis_pro_stunde;    
    const rabattpreis_10_stunden = preis_10_stunden - ( preis_10_stunden / 100 * 10);
    const rabattpreis_20_stunden = preis_20_stunden - ( preis_20_stunden / 100 * 15);


 
    let active_stunden_2 = null
    let active_stunden_5 = null
    let active_stunden_10 = null
    let active_stunden_20 = null
    let image_2=null
    let image_5=null
    let image_10=null
    let image_20=null
    let imagepath=<img src="/assets/images/check-mark.png" width="20" />;
    let rabatt_abo = 0
    let preis_wegpauschale = 0
    let rabatt = 0
    if (props.activeAbo.anzahl_lektionen === 2){active_stunden_2 = "active";preis_normal=preis_2_stunden;preis_wegpauschale=preis_wegpauschale_2h;image_2=imagepath;}
    if (props.activeAbo.anzahl_lektionen === 5){active_stunden_5 = "active"; preis_normal=preis_5_stunden;preis_wegpauschale=preis_wegpauschale_5h;image_5=imagepath;}
    if (props.activeAbo.anzahl_lektionen === 10){active_stunden_10 = "active"; preis_normal=preis_10_stunden;rabatt=10;rabatt_abo=preis_10_stunden / 100 * 10;preis_wegpauschale=preis_wegpauschale_10h;image_10=imagepath;}
    if (props.activeAbo.anzahl_lektionen === 20){active_stunden_20 = "active"; preis_normal=preis_20_stunden;rabatt=15;rabatt_abo=preis_20_stunden / 100 * 15;preis_wegpauschale=preis_wegpauschale_20h;image_20=imagepath;}
    
    let rabatt_prozentgruppe = 20;
    let rabatt_gruppe = 0;
    let tr_rabatt_gruppenunterricht = null;
    let tr_andererschueler = null;

    if (props.activeAbo.gruppenunterricht === 1 && props.activeAbo.unterrichtsort_id < 3){

        rabatt_gruppe = preis_normal / 100 * rabatt_prozentgruppe;
        
        tr_rabatt_gruppenunterricht = 
        <tr>
            <td width="70%">{props.getString('$STR_0126')} ({rabatt_prozentgruppe} % {props.getString('$STR_0117')})</td>
            <td width="30%" align="right"><font color="#ff0000">- {rabatt_gruppe.toFixed(2)} CHF</font></td>
        </tr>

        tr_andererschueler =
        <tr>
            <td>{props.getString('$STR_0153')}</td>
            <td>
                <input type="text" name="andererschueler" onChange={props.changeAbo}  value={props.activeAbo.andererschueler} className="form-control" placeholder={props.getString('$STR_0138')} />
            </td>
        </tr>
    }

    let tr_absatz_gruppenunterricht =  null;
    if (props.activeAbo.unterrichtsort_id < 3){
	tr_absatz_gruppenunterricht = 
	<tr>
		<td>			
				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" onChange={props.changeAbo}  name="gruppenunterricht" role="switch" onchange={props.changeAbo} checked={props.activeAbo.gruppenunterricht===1} />
					<label className="form-check-label" for="flexSwitchCheckDefault">{props.getString('$STR_0125')} (-{rabatt_prozentgruppe}%),<br/>{props.getString('$STR_0127')}</label>
					
				</div>
		</td>
	</tr>;
}
    let  unterrichtsorteLabel = null
    if (props.activeAbo.unterrichtsort_id===1){
        unterrichtsorteLabel=<div style={{color:'blue'}}>{props.getString('$STR_0124')}</div>
    }

    let tr_anderer_schueler = null
    if (props.activeAbo.gruppenunterricht===1){
        tr_anderer_schueler = (
            <>
            <tr>
                <td>{props.getString('$STR_0153')} *</td>
                <td>
                    <input type="text" className="form-control" name="andererschueler" value={props.activeSchueler.andererschueler} onChange={props.changeAbo}/>
                </td>
            </tr>
            <tr>
                <td>{props.getString('$STR_0208')} *</td>
                <td>
                    <input type="text" className="form-control" name="andererschueler_email" value={props.activeSchueler.andererschueler_email} onChange={props.changeAbo}/>
                </td>
            </tr>
            
            </>
        )
    }

    let tr_rabatt = null
    if (props.activeAbo.anzahl_lektionen > 5){
        tr_rabatt =
        <tr>
            <td width="70%">{props.getString('$STR_0116')} ({rabatt} % {props.getString('$STR_0117')})</td>
            <td width="30%" align="right"><font color="#ff0000">- {rabatt_abo.toFixed(2)} CHF</font></td>
        </tr>
    }
    let tr_wegpauschale = null
    if (props.activeAbo.unterrichtsort_id === 1){
        tr_wegpauschale = 
        <tr>
            <td width="70%">{props.getString('$STR_0121')}</td>
            <td width="30%" align="right">{preis_wegpauschale.toFixed(2)} CHF</td>
        </tr>
    }

    let tr_geburtsjahr = null
    if (props.activeAbo.altersgruppe==="kinder"){
        tr_geburtsjahr=(
            <tr>
                <td>{props.getString('$STR_0205')} </td>
                <td>
                    <input type="number" className="form-control" name="geburtsjahr" value={props.activeSchueler.geburtsjahr} onChange={props.changeSchuelerData}/>
                </td>
            </tr>

        )
    }

    let gesamtpreis = preis_normal - rabatt_abo - rabatt_gruppe;
    if (props.activeAbo.unterrichtsort_id ===1){
	    gesamtpreis = preis_normal - rabatt_abo  - rabatt_gruppe + preis_wegpauschale;
    }
    let str_fuer_jeden = ""
    if (props.activeAbo.gruppenunterricht===1){
        str_fuer_jeden= props.getString('$STR_0207')
    }
    let tr_gesamtpreis = (
        <tr>
            <td width="70%"><strong><big>{props.getString('$STR_0118')}</big></strong>  {str_fuer_jeden}</td>
            <td width="30%" align="right"><strong><big>{gesamtpreis.toFixed(2)} CHF</big></strong></td>
        </tr>
    )

    let errors =[]
    if (props.activeAbo.gruppenunterricht===1 && props.activeAbo.andererschueler===""){
        errors.push(<li>{props.getString('$STR_0154').trim()}</li>)
    }
    if (props.activeAbo.gruppenunterricht===1 && props.activeAbo.andererschueler_email===""){
        errors.push(<li>{props.getString('$STR_0209').trim()}</li>)
    }
    if (props.activeSchueler.anmelder_name===""){
        errors.push(<li>{props.getString('$STR_0155').trim()}</li>)
    }
    if (props.activeSchueler.email===""){
        errors.push(<li>{props.getString('$STR_0156').trim()}</li>)
    }
    if (props.activeAbo.altersgruppe==="kinder" &&  props.activeSchueler.geburtsjahr===0){
        errors.push(<li>{props.getString('$STR_0157').trim()}</li>)
    }
    if (props.activeAbo.unterrichtszeit===""){
        errors.push(<li>{props.getString('$STR_0158').trim()}</li>)
    }
    if (props.activeSchueler.plzort===""){
        errors.push(<li>{props.getString('$STR_0204').trim()}</li>)
    }
    if (props.activeAbo.datum_beginn_abo===null || props.activeAbo.datum_beginn_abo===""){
        errors.push(<li>{props.getString('$STR_0206').trim()}</li>)
    }

    let buttonSend=(
        <button disabled className="btn btn-form btn-primary display-4" >{props.getString('$STR_0162')}</button>
    )
    if (errors.length===0){buttonSend=(
            <button className="btn btn-form btn-primary display-4" onClick={()=>uebermitteln()}>{props.getString('$STR_0162')}</button>
        )
    }
    else{
        errors=<div style={{color:'red', textAlign:'left'}}><ul>{errors}</ul></div>
    }


    let form= (
        <>
        <table style={{textAlign: 'left'}}  className="table">
            <tbody>
                <tr>
                    <td>{dropdownmenu}</td>
                </tr>
                <tr>
                    <td>{unterrichtsorte}{unterrichtsorteLabel}</td>
                </tr>
                <tr>
                    <td>{dropDownAltersgruppen}</td>
                </tr>
                <tr>
                    <td>
                        <div className="btn-group" role="group" aria-label="Basic outlined example" >
                            <button onClick={()=>props.setLektionsdauer(30)} className={className30}>30 min</button>
                            <button onClick={()=>props.setLektionsdauer(45)} className={className45}>45 min</button>
                            <button onClick={()=>props.setLektionsdauer(60)} className={className60}>60 min</button>
                        </div>
                    </td>
                </tr>	
                {tr_absatz_gruppenunterricht}
                <tr>
				    <td><strong>{props.getString('$STR_0114')} </strong></td><td></td>
			    </tr>	
                <tr> 
                    <td>
                        <div className="list-group" >
                            <button onClick={()=>props.setAnzahlstunden(2)} style={{width:'100%'}} type="submit" className="list-group-item list-group-item-action"><table style={{width:'100%'}}><tr><td width="40">{image_2}</td><td width="15">2</td><td width="100" align="left">{props.getString('$STR_0115')}</td><td width="50"></td><td></td><td align="right"><strong>{preis_2_stunden} CHF</strong></td></tr></table></button>
                            <button onClick={()=>props.setAnzahlstunden(5)} style={{width:'100%'}} type="submit" className="list-group-item list-group-item-action"><table style={{width:'100%'}}><tr><td width="40">{image_5}</td><td width="15">5</td><td width="100" align="left">{props.getString('$STR_0115')}</td><td width="50"></td><td></td><td align="right"><strong>{preis_5_stunden} CHF</strong></td></tr></table></button>
                            <button onClick={()=>props.setAnzahlstunden(10)} style={{width:'100%'}} type="submit" className="list-group-item list-group-item-action"><table style={{width:'100%'}}><tr><td width="40">{image_10}</td><td width="15">10</td><td width="100" align="left">{props.getString('$STR_0115')}</td><td width="50" align="left">- 10%</td><td>{props.getString('$STR_0180')}</td><td align="right" width="80"><strong>{rabattpreis_10_stunden} CHF</strong></td></tr></table></button>
                            <button onClick={()=>props.setAnzahlstunden(20)} style={{width:'100%'}} type="submit" className="list-group-item list-group-item-action"><table style={{width:'100%'}}><tr><td width="40">{image_20}</td><td width="15">20</td><td width="100" align="left">{props.getString('$STR_0115')}</td><td width="50" align="left">- 15%</td><td>{props.getString('$STR_0180')}</td><td align="right" width="80"><strong>{rabattpreis_20_stunden} CHF</strong></td></tr></table></button>
                        </div>
                    </td>
			    </tr>

                <tr>
                    <td>
                        <div className="form-group">
                        <label>{props.getString('$STR_0202')}</label>
                        <input type="date" onChange={props.changeAbo} style={{width:'200px'}} name="datum_beginn_abo" className="form-control" value={props.activeAbo.datum_beginn_abo} /></div>
                    </td>   
                </tr>
                <tr>
                    <td>                    
                        <input type="text" value={props.activeAbo.unterrichtszeit} name="unterrichtszeit" onChange={props.changeAbo} className="form-control" placeholder={props.getString('$STR_0129')} />
                    </td>
                </tr>
            </tbody>
        </table>
        <table className="table" style={{textAlign:'left'}}>
            <tbody>
                <tr>
                    <td width={150}>{props.getString('$STR_0137')} *</td>
                    <td>
                        <input type="text" className="form-control" name="anmelder_name" value={props.activeSchueler.anmelder_name} onChange={props.changeSchuelerData}/>
                    </td>
                </tr>
                <tr>
                    <td>{props.getString('$STR_0139')} </td>
                    <td>
                        <input type="text" className="form-control" name="name" value={props.activeSchueler.name} onChange={props.changeSchuelerData}/>
                    </td>
                </tr>
                <tr>
                    <td>{props.getString('$STR_0028')} *</td>
                    <td>
                        <input type="text" className="form-control" name="email" value={props.activeSchueler.email} onChange={props.changeSchuelerData}/>
                    </td>
                </tr>
                <tr>
                    <td>{props.getString('$STR_0161')} *</td>
                    <td>
                        <input type="text" className="form-control" name="plzort" value={props.activeSchueler.plzort} onChange={props.changeSchuelerData}/>
                    </td>
                </tr>
                {tr_geburtsjahr}
                {tr_anderer_schueler}
                <tr>
                    <td>{props.getString('$STR_0181')} </td>
                    <td>
                        <textarea rows={3} className="form-control" name="bemerkungen" value={props.activeSchueler.bemerkungen} onChange={props.changeSchuelerData}/>
                    </td>
                </tr>
            </tbody>
        </table>
        <table className="table" style={{textAlign:'left'}}>
            <tbody>
                <tr>
                    <td width="50%">{props.activeAbo.anzahl_lektionen} {props.getString('$STR_0115')} x {preis_pro_stunde.toFixed(2)} CHF</td>
                    <td width="50%" align="right">{preis_normal.toFixed(2)} CHF</td>
                </tr>
				{tr_rabatt} 
                {tr_rabatt_gruppenunterricht} 
                {tr_wegpauschale}  
                {tr_gesamtpreis}
            </tbody>
        </table>
        {errors}
        {buttonSend}
	
        
        </>
    )

    function zusammenfassung (){

        let name = props.activeSchueler.name
        if (props.activeSchueler.name==="")name = props.activeSchueler.anmelder_name
       
        return(
          
        `<table style="textAlign: left"  class="table">
            <tbody>
                <tr>
                    <td width="150">${props.getString('$STR_0137')}</td>
                    <td>${props.activeSchueler.anmelder_name}</td>
                </tr>
                <tr>
                    <td>${props.getString('$STR_0203')} </td>
                    <td>${name}</td>
                </tr>
                <tr>
                    <td>${props.getString('$STR_0028')}</td>
                    <td>${props.activeSchueler.email}</td>
                </tr>
                <tr>
                    <td>${props.getString('$STR_0151')}</td>
                    <td>${kursthema}</td>
                </tr>
                <tr>
                    <td>${props.getString('$STR_0111')}</td>
                    <td>${unterrichtsort}</td>
                </tr>
                <tr>
                    <td>${props.getString('$STR_0113')}</td>
                    <td>${props.activeAbo.lektionsdauer}</td>
                </tr>
                <tr>
                    <td>${props.getString('$STR_0189')}</td>
                    <td>${props.activeAbo.anzahl_lektionen}</td>
                </tr>
                <tr>
                    <td>${props.getString('$STR_0202')}</td>
                    <td>${convertDate(props.activeAbo.datum_beginn_abo)}</td>
                </tr>
                <tr>
                    <td>${props.getString('$STR_0125')}</td>
                    <td>${props.activeAbo.bemerkungen}</td>
                </tr>
                 <tr>
                    <td>${props.getString('$STR_0153')}</td>
                    <td>${props.activeAbo.andererschueler} </td>
                </tr>
            </tbody>
        </table>
        <table class="table" style="text-align: left;">
            <tbody>
                <tr>
                    <td width="50%">${props.activeAbo.anzahl_lektionen} ${props.getString('$STR_0115')} x ${preis_pro_stunde.toFixed(2)} CHF</td>
                    <td width="50%" align="right">${preis_normal.toFixed(2)} CHF</td>
                </tr>
                <tr>
                    <td width="70%">${props.getString('$STR_0116')} (${rabatt} % ${props.getString('$STR_0117')})</td>
                    <td width="30%" align="right"><font color="#ff0000">- ${rabatt_abo.toFixed(2)} CHF</font></td>
                </tr>
                <tr>
                    <td width="70%">${props.getString('$STR_0126')} (${rabatt_prozentgruppe} % ${props.getString('$STR_0117')})</td>
                    <td width="30%" align="right"><font color="#ff0000">- ${rabatt_gruppe.toFixed(2)} CHF</font></td>
                </tr>
                <tr>
                    <td width="70%">${props.getString('$STR_0121')}</td>
                    <td width="30%" align="right">${preis_wegpauschale.toFixed(2)} CHF</td>
                </tr>
                <tr>
                    <td width="70%"><strong><big>${props.getString('$STR_0118')}</big></strong>  ${str_fuer_jeden}</td>
                    <td width="30%" align="right"><strong><big>${gesamtpreis.toFixed(2)} CHF</big></strong></td>
                </tr>
                </tbody>
		</table>`
        )
    }

    function uebermitteln(){
        let activeAbo = props.activeAbo
        activeAbo.rabatt = rabatt
        activeAbo.gesamtpreis = gesamtpreis
        activeAbo.wegpauschale  = 0
        if (activeAbo.unterrichtsort_id===1)
            activeAbo.wegpauschale = preis_wegpauschale
        activeAbo.preis_pro_lektion = preis_pro_stunde
        activeAbo.angebot_html = zusammenfassung()

        let activeSchueler = props.activeSchueler
        if (activeSchueler.name==="" ) activeSchueler.name=activeSchueler.anmelder_name
        
        props.sendeUnterrichtsanfrage(activeAbo, activeSchueler )
    }

   
        
     


    let subcontent = (
        <div className="col row-item" style={{textAlign:'center', backgroundColor:'white',padding:'10px'}}>  	
        <h2>{props.getString('$STR_0109')}</h2>
            {form}
        </div>
    )

    let content3 = null
    let colAbo = null
    let unterricht_divs = null


    return(
        <section className="cid-r8R4yMgBSy mbr-fullscreen mbr-parallax-background" id="header1-5" style={{backgroundImage: 'url(../../../assets/images/about6.png)'}}>
         
            <div className="container text-center" data-stellar-background-ratio=".5" >
                <div className="row slider-wrapper">
                    <div className="col-md-12">
                            <div className="slider-text  slider-text-two"  style={{boxShadow: '3px 2px #232124'}}>    
                                {subcontent} {content3} {colAbo} {unterricht_divs}
                            </div>
                    </div>
                </div>
            </div>
    
        </section>
    )
}
export default Unterrichtsanfrage